import { react } from "@babel/types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";


// 다음 화살표 함수

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // 다음 화살표 CSS를 핸들링 하는 코드
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
};


// 이전 화살표 함수
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // 이전 화살표 CSS를 핸들링 하는 코드
      style={{ ...style, display: "none", background: "black" }}
      onClick={onClick}
    />
  );
};

// 슬라이더 컴포넌트
const SliderComponent = () => {
    const navigate                                = useNavigate();

    //날짜 
    const targetDate  = new Date(2025, 0, 28, 0, 0);
    const currentDate = new Date();
    
  // 슬라이더의 기능을 설정할 수 있는 코드
  const settings = {
    dots: true, // 점을 표시하는 옵션
    infinite: true, // 슬라이드가 끝까지 갔을때 반복하는 옵션
    slidesToShow: 1, // 한번에 보이는 슬라이드 갯수
    slidesToScroll: 1, // 한번에 넘어가는 슬라이드 갯수
    speed: 2000, // 슬라이드 넘어갈때 걸리는 시간
    autoplay: true, // 자동으로 슬라이드 넘기는 옵션
    autoplaySpeed: 3000, // 자동으로 슬라이드가 넘어가는 시간
    // nextArrow: <SampleNextArrow />, // 다음 화살표 옵션
    // prevArrow: <SamplePrevArrow />, // 이전 화살표 옵션
  };

  const handleClickCall = ()=>{
    document.location.href='tel:1644-2378'
  
  }

  const handleButtonClick = (page) => {
    navigate(`/${page}`);
  }

  const handleClickEvent = () => {
    document.location.href='/event/index.html';
  };

  return (
    <div className="stSlide">
      {currentDate < targetDate ? (
        <>
        <Slider {...settings}>
          <img src="img/Main/banner/webminwon_event_banner.png" alt="webminwon-event" className="slide-img" onClick={handleClickEvent}/>
          <img src="img/Main/banner/banner_kakao_20240926.png" alt="apple-kakao" className="slide-img" />
          <img src="img/Main/banner/banner7.png" alt="banner1" className="slide-img" onClick={handleClickCall}/>
        </Slider>
         </>
      ) : (
        <>
        <Slider {...settings}>
           <img src="img/Main/banner/banner_kakao_20240926.png" alt="apple-kakao" className="slide-img" />
          <img src="img/Main/banner/banner7.png" alt="banner1" className="slide-img" onClick={handleClickCall}/>
        </Slider>
        </>
      )}

    </div>
  );
};

// const StSlide = styled.div`
//   margin: auto;
//   padding-left: 50px;
//   padding-right: 50px;
//   border: 1px solid black;
// `;

export default SliderComponent;