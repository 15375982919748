import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import SideNavigation from '../../components/common/NavBar';
import { connectAxios } from '../../module/connectAxios';
import ClickButtonBigA from '../../components/common/Buttons/CllickButtonBigA';
import { memberState } from '../../recoil/memberState';
import OneButtonPopup from '../../components/common/Popup/OneButtonPopup';
import { PiEye, PiEyeSlash} from 'react-icons/pi';
import MainChat from '../../components/views/MainChat';
import SecuKitLogin from '../../components/common/Buttons/SimpleLogin/SecuKitLogin';
import { getEncrypt } from '../../module/MAES256';

function Login() {
  const navigate                          = useNavigate();
  const [content, setContent]             = useRecoilState(memberState);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [lgnGbn, setLgnGbn]               = useState('');
  const [selectLgnGbn, setSelectLgnGbn]   = useState('');
  const [showPopup, setShowPopup]         = useState(false); 
  const [showPopupTwo, setShowPopupTwo]   = useState(false); 
  const [popupText   , setPopupText]      = useState("");

  const [pwType, setpwType] = useState({
    type: "password",
    visible: false,
  });
  const [account, setAccount]             = useState({
    userId : '',
    userPw : '',
    userPhone : '',
  });

  const [user, setUser]                   = useState({
    tpid      : '',
    hakbun    : '',
    userName  : '',
    userId    : '',
    userBirth : '',
    userMail  : '',
    userPhone : '',
    userCi    : ''
  });

  //giwanNo는 스토리지에서 가져옴
  const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));

  const handleMemberClick = (content) => {
      setContent(content);
      navigate(`/member-search`);
  };

  const handleButtonClick = (page) => {
    if(page === "search-school"){
      sessionStorage.removeItem("giwanInfo");
    }
      navigate(`/${page}`);
  };

  const handleLgnGbnChange = (gbn) => {
    setAccount({userId : '', userPw : ''});
    setSelectLgnGbn(gbn);
  }

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  const onChangeAccount = (e) => {

    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeAccountPhone = (e) => {
    const phone = e.target.value
    const sanitizedPhoneNumber = phone.replace(/\D/g, '').slice(0, 11);
    setAccount({
      ...account,
      userPhone: sanitizedPhoneNumber,
    });
  }; 

  const handleCancel = () => {
    // 팝업창 닫기
    setShowPopup(false);
  };


const hadleMovePasswordChange = () =>{
    // 비밀번호 변경 이동
    navigate('/setting/member/password-change' , { state : {changeYN : "Y"}});
}

  // 이메일 형식 유효성 검사
  const isEmailValid = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
    // 이메일 형식 유효성 검사
    const isPhonValid = (phone) => {
      const phoneRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return phoneRegex.test(phone);
    };

const handlePasswordType = (e) => {
  setpwType(() => {
  // 만약 현재 pwType.visible이 false 라면
    if (!pwType.visible) {
      return { type: "text", visible: true };

//현재 pwType.visible이 true 라면
    } else {
      return { type: "password", visible: false };
    }
  });
};

const getUser = () => {
    //로그인 방식 세팅
    let type = "";
    if(selectLgnGbn === "EMAIL"){
      type = "E";
    } else {
      type = "H";
    }

    if (selectLgnGbn === "EMAIL") {
      if (!isEmailValid(account.userId)) {
        setAccount({
          ...account,
          errorMessage: '* 이메일 형식이 아닙니다.',
        });
        return;
      }
    } else{
      const phoneNumberRegex = /^[a-zA-Z0-9]+$/;


      if (!phoneNumberRegex.test(account.userId)) {
        setAccount({
          ...account,
          errorMessage: '* 숫자 또는 영문만 입력이 가능합니다.',
        });
        return;
      }
    }
    //U-04. 로그인  
    connectAxios.get('/user', {
      params: {
          type    : type,  
          giwanNo : giwan.giwanNo,
          userId  : account.userId,
          userPw  : account.userPw,
          userPhone  : account.userPhone
        }
      })
      .then(function (response) {
        const data = response.data;
        if (data.resultCode === '200') {
          const prvUser = response.data;
          const encCi = getEncrypt(prvUser.userCi);
          setUser(prevUser => ({...prvUser, userCi : encCi}));
        } else if (response.data.resultCode === '201') {
          setPopupText('회원정보가 일치하지 않습니다.');
          setAccount({
            ...account,
            errorMessage: '',
          });
          
          setShowPopup(true);
        } else if (response.data.resultCode === '202') {
          setAccount({
            ...account,
            errorMessage: '',
          });
          setPopupText(response.data.errorMessage);
          setShowPopup(true);
        }
      });
  };

  //로그인 됐을 경우 메인으로
  useEffect(() => {
    if(user.tpid){
      sessionStorage.setItem('userInfo', JSON.stringify(user));

      if(selectLgnGbn === "EMAIL"){
       // 정규 표현식 패턴을 정의
        const pattern = /^[0-9|a-z|A-Z]*$/;
        const sSpecialYn = pattern.test(account.userPw);

        if(sSpecialYn){
          setShowPopupTwo(true);
          return;
        }
      }
      navigate('/main');
    }
  }, [user]);

  //팝업창
  function messageHandler(event) {
    if (event.data === 'register' && event.origin === `${process.env.REACT_APP_FRONT_BASE_URL}`){
      navigate('/member-register');
    }

    if (event.data === 'login' && event.origin === `${process.env.REACT_APP_FRONT_BASE_URL}`) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      localStorage.removeItem('userInfo');
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      navigate('/main');
    }

    window.removeEventListener('message', messageHandler);
  }

  useEffect(() => {
      //로그인 되어 있으면 main으로 이동
      const chkUser = sessionStorage.getItem('userInfo');
      if(chkUser) {
        navigate('/main');
      }

      window.addEventListener('message', messageHandler);

      //M02. 로그인 타입 조회
      connectAxios.get('/giwan-type', {
        params: {
          giwanNo : giwan.giwanNo
        }
      })
      .then(function(response){
        let result = response.data;
  
        //로그인 화면 구분
        setLgnGbn(result.lgnGbn);
        setSelectLgnGbn(result.lgnGbn);
  
        //sessionStorage 저장
        const newGiwan    = {...giwan, lgnQryType : result.lgnQryType}
        window.sessionStorage.setItem('giwanInfo', JSON.stringify(newGiwan));
      });
  }, [giwan.giwanNo]);

  //화면 랜더링 기준
  useEffect(() => {
    setIsPageVisible(selectLgnGbn !== "");
    localStorage.setItem('memberState', "");
  }, [selectLgnGbn])

  const id_title = giwan.giwanNo === "021118" || giwan.giwanNo === "024142" || giwan.giwanNo === "021110" || giwan.giwanNo === "024152" || giwan.giwanNo === "021155" || giwan.giwanNo === "023007" || giwan.giwanNo === "023009" ? '포털 아이디' : giwan.giwanNo === "111106" ? "아이디" : '학번';

  return (
    <>
    <div className='login-screen'>
     
      {isPageVisible && (
        <>
      <div className='login-header'>
        <div className="logo">

        <img id="login-logo" 
          src={`${process.env.REACT_APP_BACK_BASE_URL}/resources/images/logo/logo_${giwan.giwanNo}.gif`} alt="logo"
          /* 서울디지털대학교, 동의대학교 로고 위치 수정 */
          className={
            giwan.giwanNo === '021125' || giwan.giwanNo === '022609' 
              ? 'move-logo'
              : giwan.giwanNo === '021154'
              ? 'move-go-10'
              : ''
          }
          
           />
          </div>
          <div className="logo-box">
        <MainChat className="main-chat"/>
        <button className="hamburger" id='main-hamburger' onClick={toggleSideNav}>
        <FaBars size={'24px'}/>
        </button>
        </div>
      </div>
      <div className='login-body'>
        <p className='copy-text'>언제 어디서든, 간편하게<br />전자증명서 발급</p>
       
       {/*성신여자대학교 과정 선택 셀렉  */}
       {giwan.giwanNo === '021154' && (
  <div className='course-select-seongsin-box'>
    <label htmlFor="course-select-seongsin">과정</label>
    <select className="dropdown" name="HAKJUK" id="course-select-seongsin">
								<option value='학부'	>학부</option>
								<option value='대학원' >대학원</option>
								<option value='국립의료원' >국립의료원</option>
								<option value='시간강사'   >시간강사</option>
								<option value='국립의료원시간강사'>국립의료원시간강사</option>
								<option value='교원'	>교원</option>
								<option value='직원' >직원</option>
								<option value='조교' >조교</option>
								<option value='연구원'   >연구원</option>
								<option value='국제교육원'>국제교육원</option>
								<option value='평생교육원'>평생교육원</option>
							</select>
  </div>
)}
        <div className='input-container'>
          <label className="id-input" htmlFor="id-input">
          {selectLgnGbn === "EMAIL" ? '아이디' :  
                id_title }
          </label>
          <input 
          type='id' 
          id='id-input' 
          value={account.userId}
          maxLength="30"
          name='userId' 
          onChange={onChangeAccount}
          placeholder={selectLgnGbn === "EMAIL" ? '이메일을 입력해 주세요.' : id_title === '학번' ? `${id_title}을 입력해주세요.` : `${id_title}를 입력해주세요.`}/>

        </div>
        {account.errorMessage && <p className="login-error-message">{account.errorMessage}</p>}
        <div className='input-container' id='eye-container'>
        <label className="password" htmlFor="password-input">비밀번호</label>
           <input
        type={pwType.type} 
           id='password-input' 
           name='userPw' 
           onChange={onChangeAccount}
           placeholder='비밀번호를 입력해 주세요.'
           value={account.userPw}/>
           <div className="eye-box" onClick={handlePasswordType}>
              {pwType.visible ? <PiEyeSlash /> : <PiEye /> }</div>
          </div>
        </div>
{/*세종대학교 휴대폰 번호 인풋  */}
        {giwan.giwanNo === '021178' && (
  <div className='phone-id-sejong-box'>
    <label htmlFor="phone-id-sejong">휴대폰 번호</label>
    <input
      type="tel"
      id="phone-id-sejong"
      name="userPhone"
      value={account.userPhone}
      onChange={onChangeAccountPhone}
      placeholder="휴대폰 번호를 입력하세요."
    />
{account.userPhone && account.userPhone.length > 0 && account.userPhone.length < 11 && (
  <p className="login-error-message">* 휴대폰 번호는 11자리를 입력해주세요.</p>
)}
  </div>
)}
        <div className='checkbox-container'>
        {/* <label htmlFor="auto-login-checkbox" className='blind'>자동로그인 </label>
          <input type='checkbox' id='auto-login-checkbox' />
          <p className='auto-login-txt'>자동로그인</p> */}
        </div>

        <ClickButtonBigA 
        onClick={() => getUser()} 
        buttonText={selectLgnGbn === "EMAIL" ? '로그인' : `${giwan.giwanName} 포털 로그인`}
        />
      
      
       {selectLgnGbn === "EMAIL" && (
            <>
            
        <div className='links-container' id={selectLgnGbn === "EMAIL" ? '' : `margin0`}>
        <button  onClick={() => handleMemberClick("idsearch")} className='link-button'>아이디 찾기</button>
         <button  onClick={() => handleMemberClick("passwardsearch")} className='link-button'>비밀번호 찾기</button>
         <button  onClick={() => handleButtonClick("member-register")} className='link-button'>회원가입</button>
        </div>
               </>
          )}
        <div className='social-login-container'>
          {selectLgnGbn === "EMAIL" && (
            <>    
              <SecuKitLogin/>
              {/* <SecuKitLogin changeVisibleFunction={changeVisibleFunction}/> */}
            </>
          )}

        {selectLgnGbn === "MULTI" && (
          <>
              <div className='divider'>
          <span>또는</span>
        </div>
          <ClickButtonBigA buttonId="blue-btn" onClick={() => handleLgnGbnChange('EMAIL')} buttonText="웹민원센터 회원 로그인" />
          </>
        )}

{lgnGbn === "MULTI" && selectLgnGbn === "EMAIL" && (
          <>
          <div className='divider'>
          <span>또는</span>
        </div>
          <ClickButtonBigA 
           buttonId="blue-btn"
           className="marginTop12"
           onClick={() => handleLgnGbnChange('MULTI')} buttonText={`${giwan.giwanName} 포털 로그인`} />
          </>
        )}
    
          
        <button className='goback' onClick={()=> handleButtonClick('intro')}>학교 검색으로 돌아가기 </button>

        </div>

        {showPopup && (
        <OneButtonPopup 
        onClick={handleCancel} 
        popupText={popupText}
        buttonText="확인"/>
        )}
        </>
      )}
    {showPopupTwo &&(
    <OneButtonPopup 
    onClick={hadleMovePasswordChange}
    popupText="보안정책이 변경되어" 
    popupText2="암호를 변경해야합니다." 
    buttonText="확인"


    />)}

      <SideNavigation handleNav={toggleSideNav} isOpen={isSideNavOpen}/>
    </div>
    </>
  );
}

export default Login;