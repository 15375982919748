import React, { useEffect, useState } from 'react';
import ClickButtonBig from '../components/common/Buttons/CllickButtonBig';
import { useNavigate } from 'react-router-dom';
import { connectAxios } from '../module/connectAxios';
import axios from 'axios';


const TestPage = () => {
  const navigate = useNavigate();

  const [testuser, setTestUser] = useState({
    name: '',
    birth: '',
    giwanNo: '',
  });

  const [user, setUser]                   = useState({
    tpid      : '',
    userName  : '',
    userBirth : '',
    userMail  : '',
    userTel   : '',
    userCi    : ''
  });

  const [giwanList, setGiwanList] = useState([]);
  const [giwanName, setGiwanName] = useState("");
  const [isShow, setIsShow]       = useState(false);
 
  useEffect(() => {
    chkIp();

    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("giwanInfo");

    async function getGiwanList() {
      const response = await connectAxios.get('/giwan');
      const giwanList = response.data.giwanList;
      setGiwanList(giwanList);
    }

    getGiwanList();
  }, []);

  const chkIp = async () => {
    //-----------------테스트-------------------//
    let ip;
    await axios.get('https://geolocation-db.com/json/').then((res) => {
    ip = res.data.IPv4;
    })

    if("39.117.24.254" !== ip && "39.117.24.252" !== ip) {
      navigate('/intro');
    } else {
      setIsShow(true);
    }
  };

  const onChangeAccount = (e) => {
    setTestUser({
      ...testuser,
      [e.target.name]: e.target.value,
    });
  };

  const getTestUser = () => {

    if("111109" === testuser.giwanNo){
      alert('웹민원 계정으로만 테스트 가능합니다.');
      return;
    }

  // 기관명과 주민번호, 이름을 파라미터로 전송
  connectAxios
    .get('/test-login', {
      params: {
        name: testuser.name,
        birth: testuser.birth,
      },
    })
    .then(function (response) {
      const data = response.data;
      if (data.resultCode === '200') {
        // 요청이 성공하면 데이터 설정
        setUser(data);
      } else if (response.data.resultCode === '201') {
        // 요청 실패에 대한 처리
        alert('로그인 정보가 안맞습니다.');
      }
    })
    .catch(function (error) {
      alert('잘못입력하였습니다.');
    });
  };

  useEffect(()=> {
    if(user.tpid){
      const getGiwanName = async () => {
        const response = await connectAxios.get("/giwan-info", { params : {giwanNo : testuser.giwanNo}})
        const giwan = { giwanNo: testuser.giwanNo, giwanName: response.data.giwanNe };

        //M02. 로그인 타입 조회
      connectAxios.get('/giwan-type', {
        params: {
          giwanNo : testuser.giwanNo
        }
      })
      .then(function(response){
        let result = response.data;

        //sessionStorage 저장
        const newGiwan    = {...giwan, lgnQryType : result.lgnQryType}
        window.sessionStorage.setItem('giwanInfo', JSON.stringify(newGiwan));
        sessionStorage.setItem('userInfo', JSON.stringify(user));
        navigate('/popup-notice');
      });
    }

    getGiwanName();
    }
  }, [user])

  return (
    <>
    {isShow && (
      <div className="intro-screen" id="intro-screen">
      <label>테스트 정보 입력</label>
      <div>
        <label>기관 코드</label>
        <input
          type="text"
          name="giwanNo"
          value={testuser.institution}
          onChange={onChangeAccount}
        />
      </div>
      <div>
        <label>이름</label>
        <input
          type="text"
          name="name"
          value={testuser.name}
          onChange={onChangeAccount}
        />
      </div>
      <div>
        <label>생년월일</label>
        <input
          type="tel"
          name="birth"
          value={testuser.birth}
          onChange={onChangeAccount}
        />
      </div>
      <ClickButtonBig onClick={getTestUser} buttonText="제출" />
    </div>
    )}
    </>
  );
};

export default TestPage;