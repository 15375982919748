import { useEffect, useState } from "react";
import { connectAxios } from "../../module/connectAxios";
import OneButtonPopup from "../../components/common/Popup/OneButtonPopup";
import { useNavigate } from "react-router-dom";

const PopupNotice = () => {
    const navigate                          = useNavigate();
    const [showPopup, setShowPopup]         = useState(false);
    const [popupText, setPopupText]         = useState(null);

    const giwan   = JSON.parse(sessionStorage.getItem('giwanInfo'));
    // const isAutoLogin   = sessionStorage.getItem("isAutoLogin");

    useEffect(() => {
        let popupTextTemp = "";
        if('021156' === giwan.giwanNo){
            popupTextTemp = '학부생은 98학번 이후 발급 가능합니다.';
        }

        async function getNotice() {
        const response = await connectAxios.get('/notice', {
            params: {            
            giwanNo: giwan.giwanNo,
            type: 'popup'
            }
            });

            if(response.data.noticeList.length > 0){
                if(response.data.noticeList[0].flagOpt === "1"){
                    if(popupTextTemp === ""){
                        popupTextTemp = response.data.noticeList[0].regContent;
                    } else {
                        popupTextTemp = popupTextTemp + "</br>" + response.data.noticeList[0].regContent;
                    }
                }
            } else if (popupTextTemp === "") {
                navigate('/login');
            }

            if(popupTextTemp !== ""){
                setPopupText(popupTextTemp);
                setShowPopup(true);
            }
        }

        getNotice();        
    }, []);

    const handleCancel = () => {
        // 팝업창 닫기
        setShowPopup(false);

        // if(giwan !== null && isAutoLogin){
        //     const user = sessionStorage.getItem("userInfo");
        //     if(user !== null){
        //         navigate('/main');
        //     }
        // }

        navigate('/login');
    };

return (
    <>
    {showPopup && (
    <OneButtonPopup onClick={handleCancel} popupText={popupText} buttonText="확인"/>
    )}
    </>
);
};

export default PopupNotice;